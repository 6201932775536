var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"xw-full xpb-[60px]",attrs:{"flat":"","min-height":"80vh"}},[_c('v-card-title',{staticClass:"xflex xflex-row xjustify-between xgap-2 xitems-center"},[_c('app-search',{staticClass:"md:xmax-w-[300px] xmax-w-full",attrs:{"api":"api/regions/search","item-text":"name","item-value":"id"},on:{"selected":_vm.onSelect,"cleared":_vm.fetch}}),(_vm.$can.and('manage_region'))?_c('btn-tooltip',{attrs:{"color":"primary","tip":"Add new region"},on:{"click":function($event){return _vm.openModal(true, null)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Region ")],1):_vm._e()],1),_c('v-card-text',[_c('app-table',{attrs:{"loading":_vm.loading,"headings":_vm.headers,"items":_vm.items,"empty-text":"No regions found."},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("ucwords")(item.name)))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("format")(item.created_at,"LL")))])]}},{key:"creator",fn:function(ref){
var item = ref.item;
return [_c('avatar',{staticClass:"xfont-bold",attrs:{"size":"35","user":item.creator,"with-name":""}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can.and('manage_region'))?_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openModal(true, item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-circle-edit-outline ")]),_vm._v(" Edit Region ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-trash-can-outline ")]),_vm._v(" Delete Region ")],1)],1)],1)],1):_vm._e()]}}])})],1),(_vm.next_page_url)?_c('v-card-actions',{staticClass:"xflex-col xflex xjustify-center xitems-center xw-full xh-[60px] xabsolute xbottom-0"},[_c('btn-tooltip',{attrs:{"loading":_vm.loading_more,"color":"primary","tip":"Load more..."},on:{"click":_vm.fetchMore}},[_vm._v(" Load more ")])],1):_vm._e(),_c('RegionModal',{attrs:{"item":_vm.activeItem},on:{"saved":_vm.fetch,"updated":_vm.fetch},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }